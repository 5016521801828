import React, {Component} from 'react';


import Form from './components/Form';
import Video from './components/Video'
import AppStoreBadge from './components/App-store-badge'
import GooglePlayBadge from './components/Google-play-badge'
import RedesLogo from './components/RedesLogo'

import './App.css';

class App extends Component {
  constructor(){
    super()
    this.state = {
      videoURL : "testurl"
    }
  }
  render(){
    return (
      <div className="App">
        <div className="App-header">
            <Video/>
        </div>
        <div className="App-bottom">
          <div className="App-text">
                <div className="title">
                  Flocks
                </div>
                <div className="subtitle">
                  a beautiful puzzler
                </div>
                <div className="subtitle-small">
                  about managing groups
                </div>
            </div>

            <div className="App-subscribe">
              <Form/>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <GooglePlayBadge/>
              <AppStoreBadge/>
            </div>
            <div className="AppStore">
              <div style={{fontSize: '12px'}}>
                <a href="https://flocksgame.com/blog/" style={{textDecoration: 'none', color: '#666666'}} target="_blank">devlog</a>
                &nbsp;&nbsp;·&nbsp;&nbsp;
                <a href="mailto:flocks@nada.studio" style={{textDecoration: 'none', color: '#666666'}}>contact us</a>
              </div>
            </div>

          </div>
          <div className="RedesLogo">
            <RedesLogo/>
          </div>
        {/*<div className="App-footer">
          @wearenada
    </div>*/}
      </div>
    );
  }
}

export default App;
