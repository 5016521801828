import React, {Component} from 'react'

class Form extends Component{
    constructor(props){
        super(props)
        this.state = {
            mail: '',
        }
    }
    _onChange = (t) => {
        this.setState({
            mail: t.target.value,
        })
    }
    _onKeyPress = (e) => {
        if(e.key === 'Enter'){
            //do something
            console.log('ENTER');
        }
    }
    render(){
        if(this.state===undefined || this.state.mail===undefined) return <div/>
        return(
            <div style={{width: '80%'}}>
                <form action="https://studio.us15.list-manage.com/subscribe/post" method="POST" target="_blank" noValidate>
                <input type="hidden" name="u" value="424dad2b20fa949a15c4b6dfb"/>
                <input type="hidden" name="id" value="d2b4ba9926"/> {/* starman audience, now used as default for puzzlers */}
                <input type="checkbox" value="2" name="group[12364]" id="mce-group[12364]-12364-1" checked style={{"display":"none"}}/>
                <input
                    type="email" 
                    name="EMAIL"    
                    id="MERGE0"
                    style={this._style.formInput}
                    onChange={(t)=>this._onChange(t)}
                    onKeyPress={(e)=>this._onKeyPress(e)}
                    value={this.state.mail}
                    placeholder="subscribe to our mailing list"
                    autoCapitalize="off" 
                    autoCorrect="off"
                    />
                </form>
            </div>
        )
    }

    _style = ({
        formInput: {
            fontFamily: 'Museo',
            width: '100%',
            maxWidth: '240px',
            backgroundColor: '#f5d3cb',
            border: 0,
            padding: '10px',
            fontSize: '16px',
            textAlign: 'center',
            borderRadius: '5px'
        }
    })
}

export default Form