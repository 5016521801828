import React, {Component} from 'react'

class GooglePlayBadge extends Component{
    constructor(props){
        super(props)
    }

    _onPress = () => {
        var url = 'https://play.google.com/store/apps/details?id=games.nadastudio.android.flocks';
        var win = window.open(url, '_blank');
        win.focus();
    }
    render(){
        return(
            <div style={{ margin: '00px', display: 'flex'}}>
                <div style={this._style.badge} onClick={()=>this._onPress()}/>
            </div>
        )
    }

    _style = ({
        badge: {
            display: 'inline-block',
            overflow: 'hidden',
            cursor: 'pointer',
            background: 'url(https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png) no-repeat',
            width: '160px',
            height: '60px',
            backgroundSize: 'contain',
        }
    })
}

export default GooglePlayBadge