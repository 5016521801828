import React, {Component} from 'react'
import Logos from '../assets/Logos.png'

class RedesLogo extends Component{
    
    render(){
        return(
            <div style={{width: '80%', margin: '00px'}}>
                <img src={Logos} alt="redes"/>
            </div>
        )
    }
}

export default RedesLogo